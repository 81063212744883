var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h2", { staticClass: "font-semibold" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.resources.CustomizedCommissions.i18n) ||
                  _vm.resources.CustomizedCommissions.name
              ) +
              "\n    "
          )
        ]),
        _c("h5", { staticClass: "font-semibold mt-4 mb-2" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgConsiderInformation.i18n) ||
                  _vm.alerts.MsgConsiderInformation.i18n
              ) +
              "\n    "
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgFinancialCharge.i18n) ||
                  _vm.alerts.MsgFinancialCharge.i18n
              ) +
              "."
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgFinancialChargeAdded.i18n) ||
                  _vm.alerts.MsgFinancialChargeAdded.i18n
              ) +
              "."
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgFinancialChargeFee.i18n) ||
                  _vm.alerts.MsgFinancialChargeFee.i18n
              ) +
              "."
          )
        ]),
        _c("br"),
        _vm.pendingValue < -1
          ? _c("h4", { staticClass: "text-center text-danger" }, [
              _vm._v(
                "\n      USD $" +
                  _vm._s(_vm.pendingValue) +
                  "\n      " +
                  _vm._s(
                    _vm.$t(_vm.alerts.MsgTotalPendingCharges.i18n) ||
                      _vm.alerts.MsgTotalPendingCharges.i18n
                  ) +
                  ".\n    "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 mb-base mt-6" },
            [
              _c(
                "vx-card",
                {
                  staticClass: "text-center bg-primary-gradient greet-user",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("img", {
                    staticClass: "decore-left",
                    attrs: {
                      src: require("@/assets/images/elements/decore-left.png"),
                      alt: "Decore Left",
                      width: "150"
                    }
                  }),
                  _vm.total > 0
                    ? _c("feather-icon", {
                        staticClass:
                          "\n              p-6\n              mb-8\n              bg-primary\n              inline-flex\n              rounded-full\n              text-white\n              shadow\n            ",
                        attrs: { icon: "AwardIcon", svgClasses: "h-8 w-8" }
                      })
                    : _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "sentiment_very_dissatisfied",
                          size: "large",
                          color: "rgb(255,255,255)"
                        }
                      }),
                  _vm.total > 0
                    ? _c("h1", { staticClass: "mb-4 text-white" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Congratulations.i18n) ||
                                _vm.resources.Congratulations.name
                            ) +
                            "\n            " +
                            _vm._s(_vm.username) +
                            ",\n          "
                        )
                      ])
                    : _c("h1", { staticClass: "mb-6 text-white" }, [
                        _vm._v(_vm._s(_vm.username) + ",")
                      ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgYouHaveEarned.i18n) ||
                              _vm.alerts.MsgYouHaveEarned.name
                          ) +
                          "\n            "
                      ),
                      _c("strong", [_vm._v("USD $" + _vm._s(_vm.total))]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgTipAndFee.i18n) ||
                              _vm.alerts.MsgTipAndFee.name
                          ) +
                          ".\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 mb-base mt-6" },
            [
              _c("h4", { staticClass: "font-semibold text-center mt-6" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.TipAndCustomizedFee.i18n) ||
                        _vm.resources.TipAndCustomizedFee.name
                    ) +
                    "\n        "
                )
              ]),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.TipsTotal.i18n) ||
                    _vm.resources.TipsTotal.name
                },
                model: {
                  value: _vm.tipTotal,
                  callback: function($$v) {
                    _vm.tipTotal = $$v
                  },
                  expression: "tipTotal"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.FeeTotal.i18n) ||
                    _vm.resources.FeeTotal.name
                },
                model: {
                  value: _vm.feeTotal,
                  callback: function($$v) {
                    _vm.feeTotal = $$v
                  },
                  expression: "feeTotal"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base text-danger",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.FinancialCharge.i18n) ||
                    _vm.resources.FinancialCharge.name
                },
                model: {
                  value: _vm.charges,
                  callback: function($$v) {
                    _vm.charges = $$v
                  },
                  expression: "charges"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base text-danger",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.TotalPendingCharges.i18n) ||
                    _vm.resources.TotalPendingCharges.name
                },
                model: {
                  value: _vm.pendingValue,
                  callback: function($$v) {
                    _vm.pendingValue = $$v
                  },
                  expression: "pendingValue"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h2", { staticClass: "font-semibold mb-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.resources.Filters.i18n) || _vm.resources.Filters.name
              ) +
              "\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "vx-row mb-base mt-4" },
          [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c("label", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(_vm.resources.StartDate.i18n) ||
                          _vm.resources.StartDate.name
                      ) +
                      "\n        "
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.start_date,
                    callback: function($$v) {
                      _vm.start_date = $$v
                    },
                    expression: "start_date"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndDate.i18n) ||
                        _vm.resources.EndDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.end_date,
                    callback: function($$v) {
                      _vm.end_date = $$v
                    },
                    expression: "end_date"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-6" }, [
              _c("ul", { staticClass: "vx-col w-full" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.isCreationDate,
                          callback: function($$v) {
                            _vm.isCreationDate = $$v
                          },
                          expression: "isCreationDate"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreationDate.i18n) ||
                              _vm.resources.CreationDate.name
                          ) +
                            "\n              -\n              " +
                            _vm._s(
                              _vm.$t(_vm.resources.Booking.i18n) ||
                                _vm.resources.Booking.name
                            )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("vs-button", {
              staticClass: "mt-5",
              attrs: {
                color: "primary",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-refresh-ccw"
              },
              on: {
                click: function($event) {
                  return _vm.cleanDate()
                }
              }
            }),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Type.i18n) ||
                          _vm.resources.Type.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.typeOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Stage.i18n) ||
                        _vm.resources.Stage.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.stageList,
                    reduce: function(x) {
                      return x.id
                    },
                    label: "name",
                    scrollable: ""
                  },
                  model: {
                    value: _vm.stageId,
                    callback: function($$v) {
                      _vm.stageId = $$v
                    },
                    expression: "stageId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name,
                      maxlength: "20"
                    },
                    model: {
                      value: _vm.bookingCode,
                      callback: function($$v) {
                        _vm.bookingCode = $$v
                      },
                      expression: "bookingCode"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                        _vm.resources.TransactionNumber.name,
                      maxlength: "20"
                    },
                    model: {
                      value: _vm.transactionId,
                      callback: function($$v) {
                        _vm.transactionId = $$v
                      },
                      expression: "transactionId"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.getSummary()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.TipsTotal.i18n) ||
                        _vm.resources.TipsTotal.i18n
                    ) +
                    "\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.tipSummaryTotal))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.FeeTotal.i18n) ||
                        _vm.resources.FeeTotal.i18n
                    ) +
                    "\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.feeSummaryTotal))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.FinancialCharge.i18n) ||
                        _vm.resources.FinancialCharge.i18n
                    ) +
                    "\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.chargesTotal))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.TotalPendingCharges.i18n) ||
                        _vm.resources.TotalPendingCharges.i18n
                    ) +
                    "\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.pendingSummaryValue))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.FinancialCharge.i18n) ||
                        _vm.resources.FinancialCharge.i18n
                    ) +
                    "\n          - BlockPay\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.taxBlockPay))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(_vm.resources.FinancialCharge.i18n) ||
                        _vm.resources.FinancialCharge.i18n
                    ) +
                    "\n          - Ewallet\n        "
                )
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.taxEwallet))])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full md:w-1/6 mb-4" },
          [
            _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
              _c("h5", { staticClass: "mb-2 text-primary" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Total.i18n) ||
                        _vm.resources.Total.i18n
                    )
                  )
                ])
              ]),
              _c("h2", { staticClass: "text-success" }, [
                _c("strong", [_vm._v("USD " + _vm._s(_vm.totalSummary))])
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c("vx-card", { staticClass: "mb-base mt-4" }, [
            _c("h2", { staticClass: "font-semibold mb-4" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { attrs: { id: "ag-grid-demo mt-base" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex flex-wrap justify-between items-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                      },
                      [
                        _c(
                          "vs-dropdown",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { "vs-trigger-click": "" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                  p-4\n                  border border-solid\n                  d-theme-border-grey-light\n                  rounded-full\n                  d-theme-dark-bg\n                  cursor-pointer\n                  flex\n                  items-center\n                  justify-between\n                  font-medium\n                "
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentPage * _vm.paginationPageSize -
                                        (_vm.paginationPageSize - 1)
                                    ) +
                                      "\n                  -\n                  " +
                                      _vm._s(
                                        _vm.dataList.length -
                                          _vm.currentPage *
                                            _vm.paginationPageSize >
                                          0
                                          ? _vm.currentPage *
                                              _vm.paginationPageSize
                                          : _vm.dataList.length
                                      ) +
                                      "\n                  of " +
                                      _vm._s(_vm.dataList.length)
                                  )
                                ]),
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronDownIcon",
                                    svgClasses: "h-4 w-4"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "vs-dropdown-menu",
                              [
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          20
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("20")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          50
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("50")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          100
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("100")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          150
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("150")])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n              flex flex-wrap\n              items-center\n              justify-between\n              ag-grid-table-actions-right\n            "
                      },
                      [
                        _c("vs-input", {
                          staticClass: "mb-4 md:mb-0 mr-4",
                          attrs: {
                            placeholder:
                              _vm.$t(_vm.resources.Search.i18n) ||
                              _vm.resources.Search.name
                          },
                          on: { input: _vm.updateSearchQuery },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        }),
                        _c(
                          "vs-button",
                          {
                            staticClass: "mb-4 md:mb-0",
                            on: {
                              click: function($event) {
                                return _vm.gridApi.exportDataAsCsv()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(_vm.resources.ExportAs.i18n) ||
                                    _vm.resources.ExportAs.name
                                ) +
                                "\n              CVS\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("ag-grid-vue", {
                  staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                  attrs: {
                    gridOptions: _vm.gridOptions,
                    columnDefs: _vm.columnDefs,
                    defaultColDef: _vm.defaultColDef,
                    rowData: _vm.dataList,
                    rowSelection: "single",
                    colResizeDefault: "shift",
                    animateRows: true,
                    floatingFilter: true,
                    pagination: true,
                    paginationPageSize: _vm.paginationPageSize,
                    suppressPaginationPanel: true
                  },
                  on: { "row-selected": _vm.onRowSelected }
                }),
                _c("vs-pagination", {
                  attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Description.i18n) ||
              _vm.resources.Description.i18n,
            active: _vm.popupDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popupDetail = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-5 text-center" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mb-4",
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.goBooking(_vm.bookingId)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.BookingDetail.i18n) ||
                          _vm.resources.BookingDetail.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("h4", { staticClass: "text-center font-bold mb-2 mt-2" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(_vm.resources.Comments.i18n) ||
                    _vm.resources.Comments.i18n
                ) +
                ":\n    "
            )
          ]),
          _c(
            "h5",
            { staticClass: "text-center text-primary font-bold mb-base" },
            [_vm._v("\n      " + _vm._s(_vm.infoPopup) + "\n    ")]
          ),
          _c("p", { staticClass: "text-center mb-base" }, [
            _vm._v(_vm._s(_vm.description))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }