<template>
  <div>
    <vx-card class="mb-4">
      <h2 class="font-semibold">
        {{
          $t(resources.CustomizedCommissions.i18n) ||
            resources.CustomizedCommissions.name
        }}
      </h2>
      <h5 class="font-semibold mt-4 mb-2">
        {{
          $t(alerts.MsgConsiderInformation.i18n) ||
            alerts.MsgConsiderInformation.i18n
        }}
      </h5>
      <p>- {{ $t(alerts.MsgFinancialCharge.i18n) || alerts.MsgFinancialCharge.i18n }}.</p>
      <p>- {{ $t(alerts.MsgFinancialChargeAdded.i18n) || alerts.MsgFinancialChargeAdded.i18n }}.</p>
      <p>- {{ $t(alerts.MsgFinancialChargeFee.i18n) || alerts.MsgFinancialChargeFee.i18n }}.</p>
      <br />
      <h4 v-if="pendingValue < -1" class="text-center text-danger">
        USD ${{ pendingValue }}
        {{
          $t(alerts.MsgTotalPendingCharges.i18n) ||
            alerts.MsgTotalPendingCharges.i18n
        }}.
      </h4>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base mt-6">
          <vx-card
            slot="no-body"
            class="text-center bg-primary-gradient greet-user"
          >
            <img
              src="@/assets/images/elements/decore-left.png"
              class="decore-left"
              alt="Decore Left"
              width="150"
            />
            <feather-icon
              icon="AwardIcon"
              class="
                p-6
                mb-8
                bg-primary
                inline-flex
                rounded-full
                text-white
                shadow
              "
              svgClasses="h-8 w-8"
              v-if="total > 0"
            ></feather-icon>
            <vs-icon
              icon-pack="material-icons"
              icon="sentiment_very_dissatisfied"
              size="large"
              color="rgb(255,255,255)"
              v-else
            ></vs-icon>
            <h1 class="mb-4 text-white" v-if="total > 0">
              {{
                $t(resources.Congratulations.i18n) ||
                  resources.Congratulations.name
              }}
              {{ username }},
            </h1>
            <h1 class="mb-6 text-white" v-else>{{ username }},</h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
              {{
                $t(alerts.MsgYouHaveEarned.i18n) || alerts.MsgYouHaveEarned.name
              }}
              <strong>USD ${{ total }}</strong>
              {{ $t(alerts.MsgTipAndFee.i18n) || alerts.MsgTipAndFee.name }}.
            </p>
          </vx-card>
        </div>
        <div class="vx-col w-full lg:w-1/2 mb-base mt-6">
          <h4 class="font-semibold text-center mt-6">
            {{
              $t(resources.TipAndCustomizedFee.i18n) ||
                resources.TipAndCustomizedFee.name
            }}
          </h4>
          <vs-input
            type="number"
            class="w-full font-semibold mb-base"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="$t(resources.TipsTotal.i18n) || resources.TipsTotal.name"
            v-model="tipTotal"
          />
          <vs-input
            type="number"
            class="w-full font-semibold mb-base"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="$t(resources.FeeTotal.i18n) || resources.FeeTotal.name"
            v-model="feeTotal"
          />
          <vs-input
            type="number"
            class="w-full font-semibold mb-base text-danger"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="
              $t(resources.FinancialCharge.i18n) ||
                resources.FinancialCharge.name
            "
            v-model="charges"
          />
          <vs-input
            type="number"
            class="w-full font-semibold mb-base text-danger"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="
              $t(resources.TotalPendingCharges.i18n) ||
                resources.TotalPendingCharges.name
            "
            v-model="pendingValue"
          />
        </div>
      </div>
    </vx-card>
    <vx-card class="mb-4">
      <h2 class="font-semibold mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h2>
      <div class="vx-row mb-base mt-4">
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label>
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="start_date"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label>{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="end_date"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-6">
          <ul class="vx-col w-full">
            <li>
              <vs-checkbox v-model="isCreationDate"
                >{{
                  $t(resources.CreationDate.i18n) || resources.CreationDate.name
                }}
                -
                {{
                  $t(resources.Booking.i18n) || resources.Booking.name
                }}</vs-checkbox
              >
            </li>
          </ul>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate()"
        ></vs-button>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="type"
              :clearable="true"
              :options="typeOption"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="x => x.id"
            label="name"
            scrollable
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.BookingCode.i18n) || resources.BookingCode.name
              "
              maxlength="20"
              v-model="bookingCode"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.TransactionNumber.i18n) ||
                  resources.TransactionNumber.name
              "
              maxlength="20"
              v-model="transactionId"
            />
          </div>
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="getSummary()">{{
            $t(resources.Search.i18n) || resources.Search.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{ $t(resources.TipsTotal.i18n) || resources.TipsTotal.i18n }}
          </h5>
          <h2 class="text-success">
            <strong>USD {{ tipSummaryTotal }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{ $t(resources.FeeTotal.i18n) || resources.FeeTotal.i18n }}
          </h5>
          <h2 class="text-success">
            <strong>USD {{ feeSummaryTotal }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{
              $t(resources.FinancialCharge.i18n) ||
                resources.FinancialCharge.i18n
            }}
          </h5>
          <h2 class="text-success">
            <strong>USD {{ chargesTotal }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{
              $t(resources.TotalPendingCharges.i18n) ||
                resources.TotalPendingCharges.i18n
            }}
          </h5>
          <h2 class="text-success">
            <strong>USD {{ pendingSummaryValue }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{
              $t(resources.FinancialCharge.i18n) ||
                resources.FinancialCharge.i18n
            }}
            - BlockPay
          </h5>
          <h2 class="text-success">
            <strong>USD {{ taxBlockPay }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            {{
              $t(resources.FinancialCharge.i18n) ||
                resources.FinancialCharge.i18n
            }}
            - Ewallet
          </h5>
          <h2 class="text-success">
            <strong>USD {{ taxEwallet }}</strong>
          </h2>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/6 mb-4">
        <vx-card class="text-center cursor-pointer">
          <h5 class="mb-2 text-primary">
            <strong>{{
              $t(resources.Total.i18n) || resources.Total.i18n
            }}</strong>
          </h5>
          <h2 class="text-success">
            <strong>USD {{ totalSummary }}</strong>
          </h2>
        </vx-card>
      </div>
    </div>
    <div>
      <vx-card class="mb-base mt-4">
        <h2 class="font-semibold mb-4">
          {{ $t(resources.Detail.i18n) || resources.Detail.name }}
        </h2>
        <div id="ag-grid-demo mt-base">
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
            <!-- ITEMS PER PAGE -->
            <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="
                    p-4
                    border border-solid
                    d-theme-border-grey-light
                    rounded-full
                    d-theme-dark-bg
                    cursor-pointer
                    flex
                    items-center
                    justify-between
                    font-medium
                  "
                >
                  <span class="mr-2"
                    >{{
                      currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                    }}
                    -
                    {{
                      dataList.length - currentPage * paginationPageSize > 0
                        ? currentPage * paginationPageSize
                        : dataList.length
                    }}
                    of {{ dataList.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                    <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                    <span>150</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                :placeholder="
                  $t(resources.Search.i18n) || resources.Search.name
                "
              />
              <vs-button
                class="mb-4 md:mb-0"
                @click="gridApi.exportDataAsCsv()"
              >
                {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
                CVS
              </vs-button>
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="dataList"
            rowSelection="single"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            @row-selected="onRowSelected"
          ></ag-grid-vue>
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </div>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Description.i18n) || resources.Description.i18n"
      :active.sync="popupDetail"
    >
      <div class="vx-row">
        <div class="vx-col w-full mt-5 text-center">
          <vs-button
            color="primary"
            class="mb-4"
            @click="goBooking(bookingId)"
            >{{
              $t(resources.BookingDetail.i18n) || resources.BookingDetail.name
            }}</vs-button
          >
        </div>
      </div>
      <h4 class="text-center font-bold mb-2 mt-2">
        {{ $t(resources.Comments.i18n) || resources.Comments.i18n }}:
      </h4>
      <h5 class="text-center text-primary font-bold mb-base">
        {{ infoPopup }}
      </h5>

      <p class="text-center mb-base">{{ description }}</p>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import moment from "moment";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      database: database,
      language: this.$i18n.locale,
      languages: lang,
      tipTotal: 0,
      feeTotal: 0,
      total: 0,
      pendingValue: 0,
      notTransactions: false,
      iboCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      username: JSON.parse(localStorage.getItem("userInfo")).name,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      popupDetail: false,
      bookingId: 0,
      infoPopup: "",
      description: "",
      start_date: null,
      end_date: null,
      isCreationDate: false,
      type: "",
      typeOption: [],
      stageId: "",
      stageList: [],
      bookingCode: "",
      transactionId: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      dataList: [],
      tipSummaryTotal: 0,
      feeSummaryTotal: 0,
      chargesTotal: 0,
      totalSummary: 0,
      pendingSummaryValue: 0,
      taxBlockPay: 0,
      taxEwallet: 0,
      charges: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          hide: true
        },
        {
          headerName: "bookingId",
          field: "bookingId",
          hide: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "chargeTypeName",
          filter: true
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          width: 150
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "bookingReference",
          filter: true,
          width: 170
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "productName",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("BookingCode"),
          field: "bookingCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "infotraxOrder",
          filter: true
        },
        {
          headerName: this.$i18n.t("Subtotal"),
          field: "totalUSD",
          filter: true,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.valueUSD < 0) {
              object = `<span class='text-danger'>${event.node.data.valueUSD}</span>`;
            } else object = object = `<span>${event.node.data.valueUSD}</span>`;

            return object;
          }
        },
        {
          headerName: this.$i18n.t("FinancialCharge"),
          field: "merchantValueUSD",
          filter: true,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.merchantValueUSD < 0) {
              object = `<span class='text-danger'>${event.node.data.merchantValueUSD}</span>`;
            } else
              object = object = `<span>${event.node.data.merchantValueUSD}</span>`;

            return object;
          }
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "valueUSD",
          filter: true,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.totalUSD < 0) {
              object = `<span class='text-danger'>${event.node.data.totalUSD}</span>`;
            } else object = object = `<span>${event.node.data.totalUSD}</span>`;

            return object;
          }
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transactionId",
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("ExchangeRate"),
          field: "rateValue",
          filter: true
        },
        {
          headerName: this.$i18n.t("Comments"),
          field: "comments",
          filter: true
        },
        {
          headerName: this.$i18n.t("Customer"),
          field: "customerName",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "bookingDate",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.total >= 2;
    },
    currentDate() {
      var date = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });
      var datetime = new Date(date);
      return datetime;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    this.$vs.loading();

    await this.getChargeType();
    await this.getStage();
    await this.getSummary();
    await this.getSummaryPending();

    this.$vs.loading.close();
  },

  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.start_date = null;
      this.end_date = null;
    },

    onRowSelected(event) {
      this.description = event.node.data.comments;
      this.infoPopup = `#${event.node.data.bookingCode} - USD ${event.node.data.totalUSD}`;
      this.bookingId = event.node.data.bookingId;
      this.popupDetail = true;
    },

    goBooking(bookingId) {
      this.popupDetail = false;
      this.$router.push({
        name: "bookingDetail",
        params: {
          bookingId: bookingId
        }
      });
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageList = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.stageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getChargeType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_COMMISSION}chargeType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.typeOption = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSummaryPending() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}historicalCharge/getSummary`,
        data: {
          iboCode: this.iboCode,
          applicationId: this.database.application.OV
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.feeTotal = result.data.data[0].fee;
            this.tipTotal = result.data.data[0].tip;
            this.charges = result.data.data[0].merchant;
            this.pendingValue = result.data.data[0].owe;
            this.total = this.feeTotal + this.tipTotal + this.pendingValue;
            await this.get();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSummary() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}historicalCharge/getSummary`,
        data: {
          iboCode: this.iboCode,
          stageId: this.stageId,
          type: this.type,
          startDate: startDate,
          endDate: endDate,
          isCreationDate: this.isCreationDate,
          bookingCode: this.bookingCode,
          transactionId: this.transactionId
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.feeSummaryTotal = result.data.data[0].fee;
            this.tipSummaryTotal = result.data.data[0].tip;
            this.chargesTotal = result.data.data[0].merchant;
            this.pendingSummaryValue = result.data.data[0].owe;
            this.taxBlockPay = result.data.data[0].taxBlock;
            this.taxEwallet = result.data.data[0].taxEw;
            this.totalSummary =
              this.feeSummaryTotal +
              this.tipSummaryTotal +
              this.chargesTotal +
              -1 * this.pendingSummaryValue;
            await this.get();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async get() {
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}historicalCharge/get`,
        data: {
          iboCode: this.iboCode,
          stageId: this.stageId,
          type: this.type,
          startDate: startDate,
          endDate: endDate,
          isCreationDate: this.isCreationDate,
          bookingCode: this.bookingCode,
          transactionId: this.transactionId
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendBlockPay() {
      this.$vs.loading();
      let _bookings = this.dataList
        .filter(x => x.stageId == 0)
        .map(function(x) {
          return x.bookingCode;
        });
      let _ids = this.dataList
        .filter(x => x.stageId == 0)
        .map(function(x) {
          return x.id;
        });

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}historicalCharge/sendBlockPay`,
        data: {
          iboCode: this.iboCode,
          amount: this.total,
          bookings: _bookings,
          ids: _ids,
          paymentBy: this.email
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: result.data.message,
              accept: this.acceptDialog
            });
            await this.getSummary();
            await this.get();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              accept: this.acceptDialog
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendEwallet() {
      this.$vs.loading();
      let _ids = this.dataList
        .filter(x => x.stageId == 0)
        .map(function(x) {
          return x.id;
        });

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}historicalCharge/sendEwallet`,
        data: {
          iboCode: this.iboCode,
          amount: this.total,
          ids: _ids,
          paymentBy: this.email
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: result.data.message,
              accept: this.acceptDialog
            });
            await this.getSummary();
            await this.get();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              accept: this.acceptDialog
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }

    // sendEwallet() {
    //   this.$vs.dialog({
    //     color: "warning",
    //     title: this.$i18n.t("Alert"),
    //     text:
    //       "Estamos trabajando en que puedas enviar dinero de fee personalizado y propinas a Ewallet. Por el momento puedes pasar el dinero a BlockPay y realizar compras, inversiones, transferir a las cuentas de otros IBOs y por supuesto a tu Ewallet.",
    //   });
    // },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
